import { useEffect } from 'react';

import { isClient } from '../utils';

type Enumerate<N extends number, Acc extends number[] = []> = Acc['length'] extends N
    ? Acc[number]
    : Enumerate<N, [...Acc, Acc['length']]>;
type HoursRange<F extends number, T extends number> = Exclude<Enumerate<T>, Enumerate<F>>;
type AllowedNumberOfHours = HoursRange<1, 24>;

/** This hook adds timeout to auto-refresh site after (by default: 2 hours)
 * to keep it up to date with the latest releases */

const useTimedPageRefresh = (numberOfHours?: AllowedNumberOfHours) => {
    useEffect(() => {
        const timeout = setTimeout(() => {
            if (isClient()) {
                window.location.reload();
            }
        }, 1000 * 60 * 60 * (numberOfHours ?? 2));

        return () => {
            clearTimeout(timeout);
        };
    }, []);
};

export default useTimedPageRefresh;
